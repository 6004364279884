import React, { useState, useEffect } from 'react';
import Col from 'components/Col';
import Row from 'components/Row';
import Text from 'components/Text';
import { ActionButton } from 'components/Button';
import { BaseForm, FormHolder } from 'components/Form';
import { OrderApi } from 'services/api/order-api';
import PhoneNumber from 'awesome-phonenumber';
import tw from 'twin.macro';
// eslint-disable-line import/no-extraneous-dependencies
import { Box } from 'grommet/components/Box';
import styled from 'styled-components';
import mpesa from '../assets/mpesa.png';

const Spinner = styled.svg(() => [tw`animate-spin -ml-1 mr-3 h-14 w-14 text-purple-800 my-4`]);

export default function Payment({ order }: { order: namespace.Order }) {
  const [loading, setLoading] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [url, setUrl] = useState(null);

  const orderAPI = new OrderApi();
  const onPayment = async (data) => {
    setLoading(true);
    const pn = new PhoneNumber(data.phone, 'KE');
    try {
      const resp = await orderAPI.makePayment({
        Amount: order.total,
        CustomerNo: pn.getNumber('e164').replace('+', ''),
        Reference: `${order.id}`
      });
      if (resp.message === 'success' || resp.message === 'error') {
        setWaiting(true);
        setUrl(resp.data?.url);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (waiting && order.status === 'fail') {
      window.alert('Request failed or cancelled. Please try again');
      setWaiting(false);
    }
  }, [order.status]);
  return (
    <Box>
      <img src={mpesa} alt="mpesa" className="w-24" />
      <Text label="Pay with MPESA" bold medium />
      {waiting && (
        <Col>
          <Text label="Please confirm your payment on your phone" large />
          <Text label="Do not leave this page" small />
          <Spinner xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle
              css={css`
                ${tw`opacity-25`}
              `}
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              css={css`
                ${tw`opacity-75`}
              `}
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </Spinner>
        </Col>
      )}
      {!waiting && (
        <FormHolder onSubmit={onPayment}>
          {({ methods }) => (
            <Col>
              <BaseForm
                pages={[
                  {
                    elements: [
                      {
                        isRequired: true,
                        name: 'phone',
                        placeholder: 'Enter your MPESA phone number',
                        defaultValue: '',
                        label: 'Phone Number',
                        full: true
                      }
                    ]
                  }
                ]}
                defaultValues={{}}
                state={{ data: {} }}
                methods={methods}
              />
              <Row>
                <ActionButton primary bold full loading={loading}>
                  Continue
                </ActionButton>
              </Row>
            </Col>
          )}
        </FormHolder>
      )}
    </Box>
  );
}
